import { useMemo } from 'react'
import { useFormatNumber } from './useFormatNumber'

export const useFormatToDecimal = ({
  fractionDigits = 2,
}: {
  fractionDigits?: number
} = {}) => {
  const formatNumberOptions = useMemo(() => {
    return {
      style: 'decimal',
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    } as Intl.NumberFormatOptions
  }, [fractionDigits])
  return useFormatNumber(formatNumberOptions)
}

const formatNumberWithoutFrictionOptions = {
  style: 'decimal',
  maximumFractionDigits: 0,
} as Intl.NumberFormatOptions

export const useFormatToCurrencyCutoff = () => {
  return useFormatNumber(formatNumberWithoutFrictionOptions)
}

export const useFormatToSoftDecimal = ({
  minFractionDigits = 0,
  maxFractionDigits = 2,
}: {
  minFractionDigits?: number
  maxFractionDigits?: number
} = {}) => {
  const formatNumberOptions = useMemo(() => {
    return {
      style: 'decimal',
      minimumFractionDigits: minFractionDigits,
      maximumFractionDigits: maxFractionDigits,
    } as Intl.NumberFormatOptions
  }, [minFractionDigits, maxFractionDigits])
  return useFormatNumber(formatNumberOptions)
}
